import React, { useState } from "react";
import { TagButton, ViewButton, PlayButton } from "../common/button"
import { VideoModal } from "../modal";
import ScrollContainer from "react-indiana-drag-scroll";
import { useRouter } from "next/router";
import Redux, { updatePath } from "@/action"
import Link from "next/link";
import { pageLink } from '@/config/site';
import { _postURL } from "@/api/index";
import _ from 'lodash';
import { _increaseView } from '@/libraries/common';
import { SkeletonCategory, SkeletonLatestNewsItem, SkeletonNewsItem, SkeletonVideoItem } from "../common/skeleton";
import { CustomLink } from "../app/customLink";

const Video = (props) => {
    const { data, loading = false } = props
    const [opened, setOpened] = useState(false)
    const [view, setView] = useState(data?.views > 0 ? parseInt(data.views) : 0);

    if(loading == true){
        return (
            <SkeletonVideoItem />
        )
    }
    return (
        <>
            <div className='news_video containerPadding'>
                <div className='news_video_box'>
                    <CustomLink href={`${pageLink.news.newsMidUrl}${data?.custom_url}`}>
                        <div 
                            // onClick={()=>{setOpened(true), _increaseView(data.news_id), setView((prevView)=>{ return prevView + 1})}} 
                            className='news_video_image' 
                        >
                            <img className="news_video_image_img" src={`${!_.isEmpty(data?.cover_image) ? process.env.NEXT_PUBLIC_FS_URL + data?.cover_image : data.thumbnail}`} loading="lazy" />
                            <PlayButton />
                        </div>
                    </CustomLink>
                    <div className='news_video_content'>
                        <CustomLink href={`${pageLink.news.newsMidUrl}${data?.custom_url}`}>
                            <div 
                                // onClick={()=>{setOpened(true), _increaseView(data.news_id), setView((prevView)=>{ return prevView + 1})}} 
                                className='fontSize_14 fontWeight_medium news_video_text'
                            >
                                {data.title}
                            </div>
                        </CustomLink>
                        <div className='news_video_bottom'>
                            <div className='news_itemBox_tag'>
                                {
                                    _.reduce(JSON.parse(data?.vehicle_brand_tag), (result, value, key)=>{
                                        if(!_.isEmpty(value)){
                                            result.push(
                                                <TagButton key={key} label={value.name} linkUrl={pageLink.vehicle.vehicleMidUrl + value.custom_url} />
                                            )
                                        }
                                        return result
                                    },[])
                                }
                                <div className="news_itemBox_view_and_date">
                                    <ViewButton views={parseInt(data?.views)}/>
                                    <p className='fontSize_12 news_latestNews_date'>
                                        {data.created_time}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <VideoModal 
                opened={opened}
                setOpened={setOpened}
                videoLink={data.youtube_url}
            /> */}
        </>
    )
}

export const NewsCategory = (props) => {
    const { data = [], activeNewsCategory, setActiveNewsCategory, subCatOpen = false, isLink = false, t, loading = false } = props
    const router = useRouter()
    const { dispatch } = Redux();

    const _handleActiveNewsCategory = (catData) => {
        if(isLink == false){
            setActiveNewsCategory(catData)
        } else {
            dispatch(updatePath({ key: "newsCategory_object", path: catData ?? {mainCat: 0, mainCatKey: null, subCat: null, activeCat: []} }));
            router.push(pageLink.news.homepage);
        }
    }

    if(loading == true){
        return (
            <>
                <SkeletonCategory />
            </>
        )
    }

    if(_.isEmpty(data)){
        return (
            <></>
        )
    }

    return (
        <>
            <ScrollContainer
                className="newsCategory_list containerPadding"
                vertical={false}
                horizontal={true}
            >
                <div onClick={()=>_handleActiveNewsCategory({mainCat: 0, mainCatKey: null, subCat: null, activeCat: []})}>
                    <div className={`fontSize_14 ${0 == activeNewsCategory?.mainCat ? 'newsCategory_text newsCategory_textActive' : 'newsCategory_text'}`}>
                        {t('all')}
                    </div>
                    {
                        0 == activeNewsCategory?.mainCat &&
                        <div className='newsCategory_active' />
                    }
                </div>
                {
                    !_.isEmpty([data]) &&
                    <>
                        {
                            _.reduce(data, (result, value, key) => {
                                result.push(
                                    <div
                                        className="newsCategory_item"
                                        key={key} 
                                        onClick={()=>{_handleActiveNewsCategory({
                                            mainCat: value.cat_id, 
                                            mainCatKey: key,
                                            subCat: 0, 
                                            activeCat: data?.[key]?.sub?.length > 0 ? _.map(data?.[key]?.sub, 'cat_id') : [value.cat_id]
                                        })}}
                                    >
                                        <div className={`fontSize_14 ${value.cat_id == activeNewsCategory?.mainCat ? 'newsCategory_text newsCategory_textActive' : 'newsCategory_text'}`}>
                                            {value.cat_title}
                                        </div>
                                        {
                                            value.cat_id == activeNewsCategory?.mainCat &&
                                            <div className='newsCategory_active' />
                                        }
                                    </div>
                                )
                                return result;
                            }, [])
                        }
                    </>
                }
            </ScrollContainer>
            {
                (subCatOpen == true && 
                activeNewsCategory?.mainCatKey >= 0 && 
                data?.[activeNewsCategory?.mainCatKey]?.sub.length > 0) &&
                <ScrollContainer
                    className="newsCategorySub_list containerPadding"
                    vertical={false}
                    horizontal={true}
                >
                    <div 
                        className={`fontSize_14 ${0 == activeNewsCategory?.subCat ? 'newsCategorySub newsCategorySub_active' : 'newsCategorySub'}`}
                        onClick={()=>{setActiveNewsCategory({
                            mainCat: activeNewsCategory.mainCat, 
                            mainCatKey: activeNewsCategory?.mainCatKey, 
                            subCat: 0,
                            activeCat: _.map(data?.[activeNewsCategory?.mainCatKey]?.sub, 'cat_id')
                        })}}
                    >
                        <p className="fontSize_14 newsCategorySub_text">
                            {t('all')}
                        </p>
                    </div>
                    {
                        _.reduce(data[activeNewsCategory?.mainCatKey].sub, (result, value, key) => {
                            result.push(
                                <div 
                                    key={key} 
                                    className={`${value.cat_id == activeNewsCategory?.subCat ? 'newsCategorySub newsCategorySub_active' : 'newsCategorySub'}`}
                                    onClick={()=>{setActiveNewsCategory({
                                        mainCat: activeNewsCategory.mainCat, 
                                        mainCatKey: activeNewsCategory?.mainCatKey, 
                                        subCat: value.cat_id, 
                                        activeCat: [value.cat_id]
                                    })}}
                                >
                                    <p className="fontSize_14 newsCategorySub_text">
                                        {value.cat_title}
                                    </p>
                                </div>
                            )
                            return result;
                        }, [])
                    }
                </ScrollContainer>
            }
        </>
    )
}

export const LatestNewsItem = (props) => {
    const { data, loading = false, type = 1 } = props

    if(type == 2 || type == 3){
        return (
            <Video data={data} loading={loading} />
        )
    }
    if(loading == true){
        return (
            <SkeletonLatestNewsItem/>
        )
    }
    return (
        <>
        {
            !_.isEmpty(data) &&
            <CustomLink href={pageLink.news.newsMidUrl + data.link}>
                <div className="latestNews containerPadding">
                    <p className="fontSize_14 fontWeight_medium latestNews_title">
                        {data.title}
                    </p>
                    <p className="fontSize_12 latestNews_date">
                        {data.created_time}
                    </p>
                    <div className='latestNews_image'>
                        <img className="latestNews_image_img" src={`${process.env.NEXT_PUBLIC_FS_URL}${data.cover_image}`} loading="lazy" />
                    </div>
                </div>
            </CustomLink>
        }
        </>
    )
}

export const NewsItem = (props) => {
    const { data, lineVisible = true, loading = false, type = 1 } = props
    if(type == 2 || type == 3){
        return (
            <>
                { 
                    lineVisible && 
                    <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                }
                <div className="newsItemsVideoTop">
                    <Video data={data} loading={loading} />
                </div>
            </>
        )
    }
    if(loading == true){
        return (
            <SkeletonNewsItem lineVisible={lineVisible} />
        )
    }
    return (
        <>
            {
                !_.isEmpty(data) && 
                <>
                    { 
                        lineVisible && 
                        <div className="dividingLinePadding"><div className="dividingLine"></div></div> 
                    }
                    <CustomLink href={pageLink.news.newsMidUrl + data.link}>
                        <div className='containerPadding'>
                            <div className='news_itemBox'>
                                <p className='fontSize_14 fontWeight_medium news_itemBox_text'>
                                    {data.title}
                                </p>
                                <div className='news_itemBox_imageList'>
                                    <div className='news_itemBox_image'>
                                        <img className="news_itemBox_image_img" src={`${process.env.NEXT_PUBLIC_FS_URL}${data.feature_image_1}`} loading="lazy" />
                                    </div>
                                    <div className='news_itemBox_image'>
                                        <img className="news_itemBox_image_img" src={`${process.env.NEXT_PUBLIC_FS_URL}${data.feature_image_2}`} loading="lazy" />
                                    </div>
                                    <div className='news_itemBox_image'>
                                        <img className="news_itemBox_image_img" src={`${process.env.NEXT_PUBLIC_FS_URL}${data.feature_image_3}`} loading="lazy" />
                                    </div>
                                </div>
                                <div className='news_itemBox_bottom'>
                                    <div className='news_itemBox_tag'>
                                        {
                                            _.reduce(JSON.parse(data?.vehicle_brand_tag), (result, value, key)=>{
                                                if(!_.isEmpty(value)){
                                                    result.push(
                                                        <TagButton key={key} label={value.name} linkUrl={pageLink.vehicle.vehicleMidUrl + value.custom_url} />
                                                    )
                                                }
                                                return result
                                            },[])
                                        }
                                        <div className="news_itemBox_view_and_date">
                                            <ViewButton views={parseInt(data?.views)}/>
                                            <p className='fontSize_12 news_latestNews_date'>
                                                {data.created_time}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomLink>
                </>
            }
        </>
    )
}